/* @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Roboto:300,700,500"); */

body {
    height: 100%;
    overflow: hidden;
    margin:0;
 } 
.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 89%;
}

.container-center-horizontal1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 120%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}
.container-center-horizontal1 > * {
  flex-shrink: 0;
  pointer-events: auto;
}


.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

:root { 
  --brandcolourshsprimary: #004225;
  --brandcolourshssecondary: #f9b03d;
  --chicago: #575757;
  --heavy-metal: #2c2c2c;
  --white: #ffffff;
 
  --font-size-l: 20px;
  --font-size-m: 16px;
  --font-size-s: 12px;
  --font-size-xl: 36px;
 
  --font-family-roboto: "Roboto", Helvetica;
}
.roboto-light-chicago-20px {
  color: var(--chicago);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 300;
}


.roboto-medium-zuccini-16px {
  color: var(--brandcolourshsprimary);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.border-1-5px-heavy-metal {
  border: 1.5px solid var(--heavy-metal);
}

.hs-desk-create-account {
  background-color: var(--white);
  display: flex;
  height: 1080px;
  width: 1920px;
}

.hs-desk-create-account .flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 440px;
  margin-top: 98px;
  min-height: 811px;
  width: 402px;
}
.hs-desk-create-account .flex-col1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 440px;
  margin-top: 98px;
  min-height: 811px;
  width: 402px;
}
.hs-desk-create-account .flex-col2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 500px;
  margin-top: 50px;
  min-height: 811px;
  width: 800px;
}

.hs-desk-create-account .ident {
  height: 226px;
  margin-right: 2.0px;
  width: 226px;
}

.hs-desk-create-account .ident1 {
  height: 226px;
  margin-right: 2.0px;
  width: 400px;
}
.hs-desk-create-account .ident2 {
  height: 300px;
  margin-right: 2.0px;
  width: 400px;
}

.hs-desk-create-account .title {
  color: var(--brandcolourshsprimary);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 500;
  letter-spacing: 0;
  margin-right: 2.0px;
  margin-top: 47px;
  min-height: 40px;
  text-align: center;
  width: 400px;
}

.hs-desk-create-account .title1 {
  color: var(--brandcolourshsprimary);
  font-family: var(--font-family-roboto);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  margin-right: 2.0px;
  margin-top: 10px;
  min-height: 30px;
  text-align: left;
  width: 400px;
}
.hs-desk-create-account .title2 {
  color: var(--brandcolourshsprimary);
  font-family: var(--font-family-roboto);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  margin-right: 2.0px;
  margin-top: 10px;
  min-height: 30px;
  text-align: right;
  width: 400px;
}

.hs-desk-create-account .overlap-group7 {
  align-items: flex-start;
  border-radius: 5px;
  display: flex;
  height: 50px;
  margin-right: 2.0px;
  margin-top: 25px;
  width: 400px;
  padding: 12.5px 8.5px;
}
.hs-desk-create-account .overlap-group17 {
  align-items: flex-start;
  border-radius: 5px;
  display: inline-block;
  height: 50px;
  
  margin-top: 25px;
  width: 250px;
  padding: 12.5px 8.5px;
}
.hs-desk-create-account .overlap-group18 {
  align-items: flex-start;
  border-radius: 5px;
  display: inline-block;
  height: 50px;
  width: 140px;
  padding: 20px 10px;


  
  background:#FDAE33;
color: var(--white);
}
.hs-desk-create-account .overlap-group18_1 {
  align-items: flex-start;
  border-radius: 5px;
  display: inline-block;
  height: 50px;
  width: 140px;
  padding: 20px 10px;


  
  background:var(--brandcolourshsprimary);
color: var(--white);
}

.hs-desk-create-account .legal-first-name {
    
  letter-spacing: 0;
  min-height: 23px;
  width: 200px;
}
.hs-desk-create-account .bordertype {
    
    background:transparent;
    border:none;
    outline:medium;
    width: 300px;
    
    
  }

.hs-desk-create-account .overlap-group {
  align-items: flex-start;
  border-radius: 5px;
  display: flex;
  height: 50px;
  margin-right: 2.0px;
  margin-top: 15px;
  min-width: 400px;
  padding: 12.5px 8.5px;
}

.hs-desk-create-account .overlap-group_p {
  align-items: flex-start;
  border-radius: 5px;
  display: flex;
  height: 30px;
  margin-right: 2.0px;
  margin-top: 10px;
  width: 400px;
  padding: 3.5px 8.5px;
}
.hs-desk-create-account .overlap-group_p_p {
  align-items: flex-start;
  border-radius: 5px;
  display: flex;
  height: 30px;
  margin-right: 2.0px;
  margin-top: 10px;
  width: 400px;
  min-height: 150px;

  padding: 3.5px 8.5px;
}
.hs-desk-create-account .overlap-group10 {
  align-items: flex-start;
  border-radius: 5px;
  display: inline-block;
  height: 50px;
  margin-right: 15.0px;
  margin-top: 15px;
  width: 50px;
  padding: 12.5px 8.5px;

}
.hs-desk-create-account .overlap-group10_p {
  align-items: flex-start;
  border-radius: 5px;
  display: inline-block;
  height: 40px;
  margin-right: 7.0px;
  margin-top: 15px;
  width: 50px;
  padding: 3.5px 0px;

}
.hs-desk-create-account .overlap-group10_p_p {
  align-items: flex-start;
  border-radius: 5px;
  display: inline-block;
  height: 40px;
  margin-right: 7.0px;
  margin-top: 15px;
  width: 250px;
  padding: 3.5px 0px;

}


.hs-desk-create-account .legal-last-name {
  letter-spacing: 0;
  min-height: 23px;
  width: 200px;
}

.hs-desk-create-account .email {
  letter-spacing: 0;
  min-height: 23px;
  width: 200px;
}

.hs-desk-create-account .password {
  letter-spacing: 0;
  min-height: 23px;
  width: 200px;
}

.hs-desk-create-account .confirm-password {
  letter-spacing: 0;
  min-height: 23px;
  width: 200px;
}

.hs-desk-create-account .flex-row {
  align-items: center;
  display: flex;
  margin-left: 7.0px;
  margin-top: 46px;
  min-width: 389px;
}

.hs-desk-create-account .fluentcheckbox-cked-16-regular {
  height: 22px;
  width: 22px;
}

.hs-desk-create-account .text-3 {
  letter-spacing: 0.80px;
  margin-left: 6px;
  min-height: 18px;
  width: 361px;
}

.hs-desk-create-account .overlap-group2 {
  align-items: flex-end;
  background-color: var(--brandcolourshsprimary);
  font-size: var(--font-size-l);
  color: var(--white);
  text-align: center;
  letter-spacing: 1.00px;
  font-family: var(--font-family-roboto);

  border-radius: 5px;
  height: 70px;
  margin-right: 2.0px;
  margin-top: 13px;
  min-width: 400px;
  padding: 13px 0;
}
.hs-desk-create-account .overlap-group2_p {
  align-items: flex-end;
  background-color: var(--brandcolourshsprimary);
  font-size: var(--font-size-l);
  font-weight: 700;
  color: var(--white);
  text-align: center;
  letter-spacing: 1.00px;
  font-family: var(--font-family-roboto);

  border-radius: 5px;
  height: 50px;
  margin-right: 2.0px;
  margin-top: 13px;
  min-width: 400px;
  padding: 3.5px 8.5px;
}
.hs-desk-create-account .overlap-group2_1 {
  align-items: flex-end;
  background-color: var(--brandcolourshsprimary);
  font-size: 20;
  color: var(--white);
  text-align: center;
  letter-spacing: 1.00px;
  font-family: var(--font-family-roboto);
  display:inline-block;
  border-radius: 5px;
  height: 70px;
  margin-right: 2.0px;
  margin-top: 13px;
  width: 130px;
  padding: 13px 0;
}
.hs-desk-create-account .overlap-group2_2 {
  align-items: flex-end;
  background-color: var(--white);
  font-size: var(--font-size-l);
  
  color: var(--brandcolourshsprimary);
  text-align: center;
  letter-spacing: 1.00px;
  font-family: var(--font-family-roboto);

  border-radius: 5px;
  height: 70px;
  margin-right: 2.0px;
  margin-top: 13px;
  min-width: 400px;
  padding: 3.5px 8.5px;
}
.hs-desk-create-account .overlap-group2_2_p {
  align-items: flex-end;
  background-color: var(--white);
  font-size: var(--font-size-l);
  font-weight: 700;

  color: var(--brandcolourshsprimary);
  text-align: center;
  letter-spacing: 1.00px;
  font-family: var(--font-family-roboto);

  border-radius: 5px;
  height: 30px;
  margin-right: 2.0px;
  margin-top: 13px;
  min-width: 400px;
  padding: 3.5px 8.5px;
}
.hs-desk-create-account .sendotp {
  position: absolute;
  width: 140px;
  height: 47px;
  left: 460px;
  top: 470px;
  
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #004225;
border-radius: 5px;
color: var(--white);

}
.hs-desk-create-account .create-account {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-weight: 700;
  letter-spacing: 1.00px;
  min-height: 23px;
  text-align: center;
  width: 400px;
}







.hs-desk-create-account .text-2 {
  color: transparent;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-weight: 500;
  letter-spacing: 0.80px;
  margin-right: 4.0px;
  margin-top: 14px;
  min-height: 18px;
  width: 378px;
}

.hs-desk-create-account .minus{
  /* Vector */ position: absolute;
   left: 150px; 
  right: 10%; top: 49.5%; bottom: 45.43%; /* british_racing_green */ 
  background: #004225; /* british_racing_green */ border: 1px solid #004225;
}

.hs-desk-create-account .span0 {
  color: var(--brandcolourshssecondary);
  letter-spacing: 0.13px;
}

.hs-desk-create-account .span1 {
  letter-spacing: 0.13px;
}

.hs-desk-create-account .side-panel {
  align-items: flex-end;
  background-color: #c4e0d3;
  display: flex;
  flex-direction: column;
  margin-left: 438px;
  min-height: 1080px;
  padding: 50px 51px;
  width: 640px;
}

.hs-desk-create-account .logohealthsmartstatement {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 79px;
  width: 496px;
}

.hs-desk-create-account .logo {
  height: 37px;
  margin-right: 0.16px;
  width: 496px;
}

.hs-desk-create-account .text-1 {
  color: #104425;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-s);
  font-weight: 500;
  height: 30px;
  letter-spacing: 3.00px;
  line-height: 1px;
  margin-top: 12px;
  text-align: center;
  width: 400px;
}

.hs-desk-create-account .overlap-group1 {
  height: 567px;
  margin-top: 153px;
  position: relative;
  width: 517px;
}

.hs-desk-create-account .overlap-group-1 {
  height: 551px;
  left: 72px;
  position: absolute;
  top: 16px;
  width: 445px;
}

.hs-desk-create-account .image-3 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 154.5px;
  display: flex;
  height: 309px;
  left: 0;
  min-width: 309px;
  padding: 54px 49px;
  position: absolute;
  top: 242px;
}

.hs-desk-create-account .screenshot-2021-03-12-at-2102-1 {
  height: 200px;
  object-fit: cover;
  width: 211px;
}

.hs-desk-create-account .image-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 128.5px;
  display: flex;
  height: 257px;
  justify-content: flex-end;
  left: 188px;
  min-width: 257px;
  padding: 42.9px 42px;
  position: absolute;
  top: 0;
}

.hs-desk-create-account .screenshot-2021-03-12-at-2104-1 {
  height: 170px;
  object-fit: cover;
  width: 172px;
}

.hs-desk-create-account .image-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 107.5px;
  display: flex;
  height: 215px;
  left: 0;
  min-width: 215px;
  padding: 37px 27px;
  position: absolute;
  top: 0;
}

.hs-desk-create-account .screenshot-2021-03-12-at-2103-1 {
  height: 140px;
  width: 161px;
}





.app {
  margin: 0 ;
}

.app i {
  font-size: 80px;
  
  animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: 1;
}

article {
  position: relative;
  width: 50px;
  height: 40px;
  margin: 5px;
  float: left;
  border: 2px solid #004225;
  box-sizing: border-box;
  border-radius: 5px;


}

article div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  transition: .5s ease;
}

article input {
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
}

input[type=checkbox]:checked ~ div {
  background-color: #f9b03d;
}



.blue-color {
  color: #50bcf2;
}

.gray-color {
  color: #555;
}

