.split {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.left {
  left: 0;
  width: 70%;
  background-color: #FFF;
}

.right {
  right: 0;
  width: 30%;
  background-color: #C4E0D4;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

p {
    text-align: left;
    margin: 5px;
}

/* Default Button style */
.btn {
    padding: 10px;
    margin: 5px;
    text-decoration: none;
    display: block;
    border: 0.5px solid;
    border-color: inherit;
    border-radius: 5px;
    cursor: pointer;
}

.btn-primary {
    background-color: #004225;
    color: #FFFFFF;
}

.btn-primary:hover {
    background-color: #006036;
    color: #FFFFFF;
}

.btn-sign-in{
  width: 100%;
}

.btn-secondary {
    background-color: #575757;
    color: #FFFFFF;
}

.btn-secondary:hover {
    background-color: #2D2D2D;
    color: #FFFFFF;
}

.btn-register {
  width: 100%;
}

.input {
  width: 92%;
  display: block;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 0.5px solid;
  border-color: inherit;
  background-color: #fff;
}