/* VARIABLES */

:root {
  /* Light Color Palette */
  --hs-primary-color: #004425;
  --hs-secondary-color: #f9b03d;
  --hs-tertiary-color: #754d24;
  --hs-quaternary-color: #cfdfd8;

  --hs-black-color: #000000;
  --hs-gray-1-color: #2c2c2e;
  --hs-gray-2-color: #48484a;
  --hs-gray-3-color: #8e8e93;
  --hs-gray-4-color: #aeaeb2;
  --hs-gray-5-color: #d1d1d1;
  --hs-gray-6-color: #f2f2f7;
  --hs-white-color: #ffffff;

  --hs-danger-color: #ff453a;
  --hs-success-color: #2dcc70;
  --hs-info-color: #0a84ff;
  --hs-warning-color: #fbc757;

  --hs-appt-video-color: #f5e1ae;
  --hs-appt-phone-color: #fcd36b;
  --hs-appt-pap-color: #fcb8c5;
  --hs-appt-comp-phy-color: #ffc075;
  --hs-appt-referral-color: #68fcf3;

  --hs-light-red: #e16b6c;
  --hs-light-green: #c4d4c4;

  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--hs-gray-2-color);
  background: var(--hs-gray-6-color);
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--hs-gray-4-color);
  background: var(--hs-gray-6-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--hs-primary-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* DASH */

.dash-header {
  height: 60px;
  margin-top: 47px;
  position: fixed;
  overflow-x: hidden;
  z-index: 1;
}

.dash-body {
  height: fit-content;
  position: absolute;
  overflow-x: hidden;
  height: calc(100vh - 47px);
}

.dash-day-left {
  left: 0;
  width: calc(50% - 60px);
  margin-left: 60px;
  margin-top: 47px;
  background-color: #fff;
}

.dash-day-left::-webkit-scrollbar {
  display: none;
}

.dash-day-middle {
  left: 50%;
  width: 30%;
  margin-top: 47px;
  padding-right: 16px;
  background-color: #fff;
}

.dash-day-right {
  right: 0;
  width: 20%;
  margin-top: 47px;
  padding-top: 60px;
  background-color: var(--hs-quaternary-color);
}

.dash-left {
  left: 0;
  width: calc(80% - 60px);
  margin-left: 60px;
  background-color: #fff;
}

.dash-right {
  right: 0;
  width: 20%;
  border-left: 1px solid var(--hs-gray-4-color);
  background-color: var(--hs-gray-6-color);
}

.cal-left {
  left: 0;
  width: calc(80% - 60px);
  margin-left: 60px;
  margin-top: 47px;
  padding-right: 16px;
  background-color: #fff;
}

.cal-right {
  right: 0;
  width: 20%;
  margin-top: 47px;
  padding-top: 60px;
  background-color: #c4e0d4;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: calc(100% / 7);
}

.wk-col {
  flex-grow: 1;
  flex-basis: 0;
  height: 36px;
  border-left: solid 1px var(--hs-gray-4-color);
  background-color: var(--neutral-color);
}

.dy-col {
  text-align: center;
  display: flex;
  flex-grow: 1;
  height: 60px;
  border-left: solid 1px var(--hs-gray-4-color);
  background-color: var(--neutral-color);
}

th.dy-col,
th.col-time {
  padding: 15px 10px;
  position: sticky;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--hs-primary-color);
  font-size: 90%;
  border-bottom: 1px solid var(--hs-tertiary-color);
  background-color: var(--hs-gray-6-color);
}

.col-time {
  justify-content: center;
  text-align: center;
  width: 55px;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.date-title {
  margin-left: 20px;
  width: fit-content;
}

.calendar-dropdown {
  align-self: center;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

.calendar-select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  min-width: 20ch;
  max-width: 35ch;

  border: 1px solid var(--hs-gray-2-color);
  border-radius: 0.25em;
  padding: 0.5em;

  font-size: 1rem;
  cursor: pointer;
  line-height: 1.25;

  background-color: var(--hs-gray-6-color);
  /* background-image: linear-gradient(to top, var(--hs-gray-5-color), var(--hs-gray-6-color) 33%); */

  /* // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  } */
}

.calendar-body {
  display: block;
  position: relative;
  margin: 0 0 10px 20px;
  max-height: 100%;
  background: var(--hs-gray-6-color);
  border: 1px solid var(--border-color);
  border-bottom: none;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 700;
  color: var(--hs-primary-color);
  font-size: 80%;
  border-bottom: 1px solid var(--hs-tertiary-color);
}

.calendar .body .cell {
  position: relative;
  height: 6em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell .month-event {
  text-align: end;
  font-size: 85%;
  padding-right: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.calendar .body .cell .month-vacation {
  text-align: center;
  justify-content: center;
  position: relative;
  padding: 1px;
  margin: 35px 10px 0 10px;
  color: var(--hs-gray-6-color);
  border-radius: 5px;
  font-size: 85%;
  font-weight: 400;
  background-color: var(--hs-info-color);
}

.calendar .body .cell .month-holiday {
  text-align: center;
  justify-content: center;
  position: relative;
  padding: 1px;
  margin: 35px 10px 0 10px;
  color: var(--hs-gray-6-color);
  border-radius: 5px;
  font-size: 85%;
  font-weight: 400;
  background-color: var(--hs-danger-color);
}

.calendar .body .cell .month-reminder {
  position: fixed;
  margin: 8px;
}

.calendar .body .cell:hover {
  background: var(--hs-gray-6-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 5px solid transparent;
  border-image: linear-gradient(
    45deg,
    var(--hs-primary-color) 0%,
    var(--hs-quaternary-color) 40%
  );
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--hs-primary-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.reminder-icon {
  color: var(--hs-info-color);
}

/* MCalendar */

.m-calendar {
  display: block;
  position: relative;
  text-align: center;
  margin: 0 5% 10% 5%;
  max-height: 100%;
  border-bottom: none;
}

.m-calendar .header {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  padding: 0.5em;
  background: none;
}

.m-calendar .days {
  text-transform: uppercase;
  font-weight: 700;
  color: var(--hs-black-color);
  font-size: 80%;
  padding: 0.75em 0;
}

.m-calendar .body .cell .number:hover {
  background: var(--hs-quaternary-color);
  color: var(--hs-primary-color);
  border-radius: 20px;
  padding: 2px 4px;
  cursor: pointer;
}

.m-calendar .body .selected .number {
  color: var(--hs-white-color);
  background-color: var(--hs-primary-color);
  border-radius: 20px;
  padding: 2px 4px;
}

.m-calendar .body .highlighted {
  color: var(--hs-primary-color);
  background-color: var(--hs-quaternary-color);
  /* border-radius: 20px;
  padding: 2px 4px; */
}

.m-calendar .body .disabled {
  color: var(--hs-gray-4-color);
  pointer-events: none;
}

.m-calendar .body .cell .number {
  font-weight: 400;
}

.m-calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: 100%;
}

.m-calendar .date-view {
  display: block;
  position: absolute;
  left: 15px;
  color: var(--hs-primary-color);
  justify-content: flex-start;
  text-align: left;
}

.m-calendar .prev-btn,
.next-btn {
  width: 25px;
  height: 25px;
  margin: 0 5px;
  border-radius: 20px;
  color: var(--hs-primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--hs-quaternary-color);
}

/*INFO PANEL*/

.info-panel-primary-card {
  margin-bottom: 16px;
  padding: 0.75em;
  background-color: var(--hs-gray-6-color);
  border: 1px solid var(--hs-primary-color);
  border-radius: 10px;
}

.info-card-title {
  font-weight: 700;
  font-size: larger;
  color: var(--hs-primary-color);
}

.info-card-subtitle {
  font-weight: 500;
  font-size: medium;
}
