.side-bar {
  background-color: var(--hs-gray-5-color);
  border-left: 1px solid var(--hs-gray-4-color);
  width: 20%;
  height: calc(100vh - 47px);
  position: fixed;
  top: 47px;
  transition: 850ms;
  right: -100%;
  z-index: 1;
  overflow-y: scroll;
}

.side-bar.active {
  right: 0;
  transition: 200ms;
}

.side-bar::-webkit-scrollbar {
  display: none;
}

.sidebar-header {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 5px;
  padding-bottom: 10px;
  color: var(--hs-primary-color);
  border-bottom: 1px solid var(--hs-gray-4-color);
  justify-content: space-between;
  align-items: center;
}

.sidebar-form {
  width: 100%;
  padding-left: 10px;
}

.close-button {
  padding: 4px;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.close-button:hover {
  background-color: var(--hs-light-green);
}

.sidebar-panel {
  display: none;
  padding-left: 30px;
  padding-right: 15px;
  transition: 1500ms;
}

.sidebar-panel.active {
  display: block;
  transition: 1500ms;
}

.sidebar-panel-handle {
  background-color: var(--hs-gray-6-color);
  color: var(--hs-primary-color);
  font-size: small;
  font-weight: 500;
  padding: 7px 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.sidebar-panel-handle-number {
  background-color: var(--hs-gray-4-color);
  color: var(--hs-gray-6-color);
  padding: 4px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: x-small;
}

.sidebar-panel-handle-chevron {
  background-color: var(--hs-white-color);
  color: var(--hs-primary-color);
  padding: 4px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.sidebar-panel-handle-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.sidebar-panel-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-submit-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
}

.search-box {
  background-color: white;
  margin: 8px 10px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.search-bar {
  border: none;
  width: 200px;
  font-size: x-small;
}

.search-bar:focus {
  outline: none;
}
