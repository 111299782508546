/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  position: relative;
}

select:focus {
  outline: none;
}

/* MAIN BODY */

.main-body {
  height: fit-content;
  position: absolute;
  overflow-x: hidden;
  height: calc(100vh - 47px);
}

/* LOGIN PANEL */

.login-panel {
  flex-direction: row;
  display: flex;
}

.login-left-panel {
  background-color: var(--hs-primary-color);
  color: var(--hs-gray-6-color);
  line-height: 0.3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 55%;
  height: 100vh;
}

.login-right-panel {
  background-color: var(--hs-gray-6-color);
  color: var(--hs-primary-color);
  line-height: 0.3;
  display: flex;
  width: 45%;
  height: 100vh;
}

.login-left-panel .panel-holder {
  width: 45%;
  height: 100vh;
  margin-right: 5%;
  text-align: center;
}

.login-right-panel .panel-holder {
  width: 45%;
  height: 100vh;
  margin-left: 5%;
}

.hs-logo-img {
  margin-top: 30%;
  width: 100%;
  margin-bottom: 5%;
}

.hs-welcome-img {
  margin-top: 10%;
  width: 100%;
  margin-bottom: 5%;
}

.hs-welcome-img-2 {
  margin-top: 10%;
  width: 60%;
  margin-bottom: 5%;
}

.login-left-panel h2 {
  color: #c6eddc;
}

.progress-bar-text {
  margin-top: 35px;
  margin-bottom: 15px;
  font-size: 0.75em;
  font-weight: 200;
}

.login-right-panel #hr01 {
  border: 2px solid var(--hs-gray-5-color);
}

.login-right-panel #hr02 {
  border: 1.5px solid var(--hs-info-color);
  background-color: var(--hs-info-color);
  position: absolute;
  top: 46px;
}

.login-right-panel .login-profile-hr#hr02 {
  width: 4%;
}

.login-right-panel .login-medical-hr#hr02 {
  width: 8%;
}

.login-right-panel .login-passport-hr#hr02 {
  width: 12%;
}

.login-right-panel .login-address-hr#hr02 {
  width: 16%;
}

.login-right-panel .login-clinic-hr#hr02 {
  width: 18%;
}

.login-header-icon {
  width: fit-content;
  position: absolute;
}

.login-header-text-01 {
  margin: 35px 0px 15px 35px;
  font-size: 0.75em;
  font-weight: 500;
}

.login-header-text-02 {
  margin-bottom: 15px;
  margin-left: 35px;
  font-size: 1.25em;
  font-weight: 500;
}

form {
  line-height: 1;
  margin-top: 25px;
}

form div {
  font-size: 0.65em;
  font-weight: 600;
  color: var(--hs-gray-3-color);
}

form div input,
select,
textarea {
  width: 100%;
  line-height: normal;
  margin-top: 7px;
  padding: 6px 8px;
  border: 1px solid var(--hs-gray-5-color);
  border-radius: 5px;
}

.submit-primary {
  background-color: var(--hs-primary-color);
  color: var(--hs-gray-6-color);
  font-size: 0.7em;
  font-weight: bold;
  padding: 7px 25px;
  border: 1.5px solid var(--hs-primary-color);
  border-radius: 20px;
  margin: 30px 3px;
}

.submit-primary:hover {
  opacity: 0.8;
}

.submit-secondary {
  background-color: var(--hs-gray-6-color);
  color: var(--hs-primary-color);
  font-size: 0.7em;
  font-weight: bold;
  padding: 7px 25px;
  border: 1.5px solid var(--hs-primary-color);
  border-radius: 20px;
  margin: 30px 3px;
}

.submit-secondary:hover {
  background-color: var(--hs-gray-5-color);
}

.edit-secondary {
  background-color: var(--hs-gray-6-color);
  color: var(--hs-primary-color);
  font-size: 0.7em;
  font-weight: bold;
  padding: 7px 25px;
  border: 1.5px solid var(--hs-primary-color);
  border-radius: 20px;
  margin: 5px 3px;
}

.edit-secondary:hover {
  background-color: var(--hs-gray-5-color);
}

.delete-secondary {
  background-color: var(--hs-gray-6-color);
  color: var(--hs-danger-color);
  font-size: 0.7em;
  font-weight: bold;
  padding: 7px 25px;
  border: 1.5px solid var(--hs-danger-color);
  border-radius: 20px;
  margin: 5px 3px;
}

.delete-secondary:hover {
  background-color: var(--hs-appt-pap-color);
}

.action-primary {
  background-color: var(--hs-primary-color);
  color: var(--hs-gray-6-color);
  font-size: 0.65em;
  font-weight: bold;
  padding: 10px 15px;
  height: max-content;
  border: 1.5px solid var(--hs-primary-color);
  border-radius: 20px;
  margin: 5px 3px;
}

.action-primary:hover {
  opacity: 0.8;
}

.form-left {
  left: 0;
  width: calc(40% - 60px);
  margin-left: 60px;
  margin-top: 47px;
  background-color: #fff;
}

.form-left::-webkit-scrollbar {
  display: none;
}

.form-80-left {
  left: 0;
  width: calc(80% - 60px);
  margin-left: 60px;
  margin-top: 47px;
  background-color: #fff;
}

.form-20-right {
  right: 0;
  width: 20%;
  margin-top: 47px;
  background-color: #c4e0d4;
}

.full-form-left {
  left: 0;
  width: calc(98% - 60px);
  margin-left: 60px;
  margin-top: 47px;
  padding-right: 16px;
  background-color: #fff;
}

.form-body {
  height: fit-content;
  position: absolute;
  overflow-x: hidden;
}

.appt-type-table td {
  line-height: normal;
  padding: 4px 6px;
  border: 1px solid var(--hs-gray-5-color);
  text-align: center;
  font-size: medium;
}

table {
  border-collapse: collapse;
}

.main-header-text-01 {
  margin-left: 10px;
  font-size: 0.8em;
  font-weight: 500;
}

.main-header-text-02 {
  margin-left: 10px;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 0.6;
}

.main-header-text-03 {
  margin-left: 10px;
  font-size: 0.75em;
  font-weight: 400;
}

.main-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.main-form-40 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 40%;
}

.main-form-field {
  width: 25%;
  margin-left: 10px;
}

.appt-timeslot-table {
  width: 100%;
}

.appt-timeslot-table input {
  width: 100%;
  border: 0px;
}

.appt-timeslot-table td {
  line-height: normal;
  padding: 5px 2px;
  margin: 15px 10px 15px 10px;
  border: 1px solid var(--hs-gray-5-color);
  text-align: center;
  font-size: medium;
}

.week-scheduler-table {
  width: 50%;
}

.week-sceduler-table th td {
  line-height: normal;
  padding: 5px 2px;
  margin: 15px 10px 15px 10px;
  font-size: medium;
}

.week-sceduler-table {
  border: 0;
}

.appt-type-code-form {
  padding: 5px 20px;
}

select {
  background-color: var(--hs-gray-6-color);
}

.appt-type-code-select-mini {
  padding: 0 5px;
  text-align: center;
  border: 0;
}

/* Day Calendar */

.header-middle {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 10px 15px 5px 5px;
  color: var(--hs-primary-color);
  background-color: var(--hs-gray-6-color);
  justify-content: space-between;
  z-index: 1;
}

.doc-day-left {
  left: 0;
  width: calc(40% - 60px);
  margin-left: 60px;
  margin-top: 47px;
  background-color: #fff;
}

.doc-day-left::-webkit-scrollbar,
.doc-day-middle::-webkit-scrollbar {
  display: none;
}

.doc-day-middle {
  left: 40%;
  width: 40%;
  margin-top: 47px;
  background-color: var(--hs-gray-6-color);
}

.doc-day-right {
  right: 0;
  width: 20%;
  margin-top: 47px;
  padding-top: 60px;
  border-left: 1px solid var(--hs-gray-4-color);
  background-color: var(--hs-gray-6-color);
}

.day-calendar-body {
  display: block;
  position: relative;
  width: 95%;
  margin: 0 20px 10px 10px;
  max-height: 100%;
  background: var(--hs-gray-6-color);
  border: 1px solid var(--hs-gray-4-color);
  border-bottom: none;
}

/* Information Panel */

.info-panel-main-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.info-panel-section {
  width: 46%;
  margin: 2%;
}

.info-panel-section-label {
  color: var(--hs-primary-color);
  font-size: small;
  font-weight: 500;
}

.info-panel-section-sub-label {
  color: var(--hs-primary-color);
  font-size: smaller;
  font-weight: 400;
}

.info-panel-section input,
select,
textarea {
  width: 100%;
  line-height: normal;
  margin-top: 7px;
  padding: 6px 8px;
  border: 1px solid var(--hs-gray-5-color);
  border-radius: 5px;
  font-size: small;
  font-weight: 300;
  background-color: var(--hs-white-color);
}

.info-panel-section textarea {
  resize: vertical;
}

.info-panel-section-card {
  width: 46%;
  margin: 2%;
  height: 45vh;
  text-align: center;
  border-radius: 15px;
  border: 1px solid var(--hs-gray-4-color);
  background-color: rgba(255, 255, 255, 0.7);
  overflow-y: scroll;
}

.info-panel-section-card::-webkit-scrollbar {
  display: none;
}

.info-panel-section-card-label {
  font-size: small;
  font-weight: 500;
  color: var(--hs-primary-color);
}

.section-card-container {
  text-align: left;
  padding: 0 10%;
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid black;
  margin-left: 10%;
  position: relative;
}

.section-card-icon-container {
  width: 22px;
  height: 22px;
  position: absolute;
  left: -12px;
  border-radius: 10px;
  color: var(--hs-white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hs-light-red);
}

.section-card-container-full {
  width: 96%;
}

.section-card-container-half {
  width: 48%;
}

.section-card-title-1 {
  text-transform: uppercase;
  font-size: x-small;
  font-weight: 400;
  color: var(--hs-primary-color);
}

.section-card-title-2 {
  font-size: xx-small;
  font-weight: 400;
  color: var(--hs-primary-color);
}

.section-card-subtitle-1 {
  font-size: small;
  font-weight: 500;
  color: var(--hs-black-color);
}

.section-card-subtitle-2 {
  font-size: small;
  font-weight: 600;
  color: var(--hs-black-color);
}

.section-card-container-half {
  margin: 12px 0;
}

/* Week Calendar */

.doc-week-left {
  left: 0;
  width: calc(80% - 60px);
  margin-left: 60px;
  margin-top: 47px;
  padding-right: 15px;
  background-color: #fff;
}

.doc-week-left::-webkit-scrollbar {
  display: none;
}

.doc-week-right {
  right: 0;
  width: 20%;
  margin-top: 47px;
  padding-top: 60px;
  border-left: 1px solid var(--hs-gray-4-color);
  background-color: var(--hs-gray-6-color);
}

.week-calendar-body {
  display: block;
  position: relative;
  width: 95%;
  margin: 0 20px 10px 10px;
  max-height: 100%;
  background: var(--hs-gray-6-color);
  border: 1px solid var(--hs-gray-4-color);
  border-bottom: none;
}

.doc-week-header {
  display: flex;
  width: 100%;
  color: var(--hs-primary-color);
  align-items: center;
  justify-content: space-between;
}

/*CARD*/

.card {
  margin: 1em;
  padding: 0.75em;
  background-color: var(--hs-quaternary-color);
  border-radius: 10px;
}

.card .card-title {
  font-weight: 600;
}
