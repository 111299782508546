.side-calendar {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.toggle-switch {
  position: relative;
  text-align: center;
  margin: 10px 20px;
  padding: 5px 0;
  border-radius: 5px;
  background-color: var(--hs-gray-5-color);
}

.toggle-switch .switch-button {
  width: 32%;
  height: 30px;
  padding: 5px;
  border: none;
  font-weight: 600;
  font-size: medium;
  cursor: pointer;
  color: var(--hs-primary-color);
  background-color: var(--hs-gray-5-color);
}

.toggle-switch .selected {
  border-radius: 0.25em;
  background-color: var(--hs-secondary-color);
}

.filter-bar {
  width: 18%;
  background-color: #f0f0f0;
  position: fixed;
  bottom: 0;
  margin-left: 1%;
  transition: 1000ms;
  border-radius: 10px 10px 0 0;
}

.filter-bar-nav {
  max-width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--primary-color);
}

.filter-bar-caret {
  display: flex;
  align-items: right;
  justify-content: right;
}

.filter-bar-name {
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
}

.filter-bar-icon {
  padding: 10px;
  font-weight: 500;
}

.filter-bar-list {
  padding-left: 10px;
  max-width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  margin: 0 10px;
  padding: 0;
  border-bottom: 0.5px solid #D5D5D5;
}

.main-calendar-view-month {
    padding-right: 5%;
}
